import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import errorManagement from "../../services/errorManagement";
import contactsDao from "../../services/settings/contactsDao";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import Select from "react-select";
import { ButtonWaiting } from "../../functions/PageAnimation";
import getUrlParam from "../../functions/StrFunctions";

const ContactsMngt = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [tab, setTab] = useState(0);
    const [groups, setGroups] = useState();
    const [newCode, setNewCode] = useState();
    const [newDescription, setNewDescription] = useState();
    const [newAuth, setNewAuth] = useState();
    const [newFirstname, setNewFirstname] = useState();
    const [newLastname, setNewLastname] = useState();
    const [newEmail, setNewEmail] = useState();
    const [err, setErr] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState();
    const [contacts, setContacts] = useState();

    const [selectList, setSelectList] = useState();
    const [selected, setSelected] = useState();


    useEffect(() => {
        var code = getUrlParam( props, "code" );
        var sel = {};

        contactsDao.getGroups().then(
            (response) => {
                var arr = new Array();
                arr.push({ value: "Choisissez...", label: 'Choisissez...' })
                response.map(v => {
                    if( code && code === v.code ) sel = { value: v.code, label: v.description, id: v.id };
                    arr.push({ value: v.code, label: v.description, id: v.id })
                });
                setSelectList(arr);
                if(sel.value ) getContactList(sel);
                setGroups(response);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }, []);

    const changeTab = (e) => {
        setTab(parseInt(e.target.getAttribute("data-tab")))
    }

    const addNewGroup = (e) => {
        e.preventDefault();
        setErr(null);
        if (!newCode || !newDescription || !newAuth) {
            setErr("Renseignez tous les champs!");
            return;
        }

        setLoading(true);
        contactsDao.addGroup(newCode, newDescription, newAuth).then(
            (response) => {
                setGroups(response);
                setLoading(false);
                setNewCode("");
                setNewDescription("");
                setNewAuth("");
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    const onNewCodeChange = (e) => {
        setNewCode(e.target.value);
    }

    const onNewDescriptionChange = (e) => {
        setNewDescription(e.target.value);
    }

    const onNewAuthChange = (e) => {
        setNewAuth(e.target.value);
    }

    const onNewFirstnameChange = (e) => {
        setNewFirstname(e.target.value);
    }

    const onNewLastnameChange = (e) => {
        setNewLastname(e.target.value);
    }

    const onNewEmailChange = (e) => {
        setNewEmail(e.target.value);
    }

    const onSearchChange = (e) => {
        setContacts();
        setSearch(e.target.value);
    }

    const deleteGroup = (e) => {
        if (!window.confirm("Sûr ?")) return;

        setLoading(true);
        contactsDao.deleteGroup(e.target.getAttribute("data-id")).then(
            (response) => {
                setGroups(response);
                setLoading(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }

        );
    }

    const getContactList = (e) => {
        setErr("");
        setLoading(true);
        setContacts();
        setSelected(e);

        setSearch(e.value);

        contactsDao.getContactsByGroup(e.value).then(
            (response) => {
                setContacts(response);
                setLoading(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const updateContact = (e) => {
        e.preventDefault();
        setErr();
        var lastname = e.target.lastname.value;
        var firstname = e.target.surname.value;
        var email = e.target.email.value;
        var id = e.target.id.value;

        if (lastname === "" || firstname === "" || email === "") {
            setErr("Veuillez compléter tous les champs");
            return;
        }

        contactsDao.updateContact(id, firstname, lastname, email);
    }

    const addContact = (e) => {
        e.preventDefault();

        setErr("");
        if (newLastname === "" || newFirstname === "" || newEmail === "" || search === "") {
            setErr("Veuillez compléter tous les champs!");
            return;
        } else if (!isEmail(newEmail)) {
            setErr("Veuillez saisir une adresse mail valide!");
            return;
        }

        setLoading(true);
        contactsDao.addContact(search, newFirstname, newLastname, newEmail).then(
            (response) => {
                setContacts(response);
                setLoading(false);
                setNewFirstname("")
                setNewLastname("");
                setNewEmail("");
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const deleteContact = (e) => {
        confirmAlert({
            title: "Annulation de partage",
            message: "Etes-vous sûr de vouloir supprimer ce contact ? ",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        contactsDao.deleteContact(e.target.getAttribute("data-id"), search).then(
                            (response) => {
                                setContacts(response);
                                setLoading(false);
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });


    }

    /**
     * 
     */
    const updateGroup = (k) => {
        setLoading(true);
        contactsDao.updateGroup(groups[k].id, groups[k].description, groups[k].authorization).then(
            (response) => {
                setLoading(false);
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const onGroupChange = (e, k, field) => {
        var arr = [...groups];
        arr[k][field] = e.target.value;
        setGroups(arr);
    }

    return (
        <div>
            <h2 className="text-center">Gestion des contacts</h2>
            <br>
            </br>

            {err && <div className="alert alert-danger" role="alert">{err}</div>}
            {!groups && <WaitingRoundSnippers />}

            {
                groups &&
                <div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                                aria-current="page" href="#" data-tab="0" onClick={changeTab}>Carnet d'adresse</a>
                        </li>
                        {
                            currentUser.roles.includes("ROLE_ADMIN") &&
                            <li className="nav-item">
                                <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                                    href="#" data-tab="1" onClick={changeTab}>Groupe de contacts</a>
                            </li>
                        }

                    </ul>
                    <br></br>

                    <div className={(tab !== 0 ? "visually-hidden" : "")}>
                        <div className="col-6 offset-3">
                            {!loading && <Select options={selectList} onChange={getContactList} value={selected}
                                className="fillInBlank text-center fw-bold" />}
                            {loading && <WaitingRoundSnippers />}
                        </div>

                        <br></br>

                        {
                            contacts &&
                            <div>
                                {contacts.map((v) =>
                                    <form key={"ct-" + v.email} className="col-10 offset-1" onSubmit={updateContact}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Nom"
                                                id="lastname" name="lastname" defaultValue={v.name} />
                                            <input type="text" className="form-control" placeholder="Prénom"
                                                id="surname" name="surname" defaultValue={v.surname} />
                                            <input type="text" className="form-control" placeholder="E-mail"
                                                id="email" name="email" defaultValue={v.email} />
                                            <input type="hidden" name="id" id="id" defaultValue={v.id}></input>
                                            <button type="submit" className="btn btn-warning" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Mettre à jour</span>
                                            </button>
                                            <button type="button" className="btn btn-danger" disabled={loading}
                                                onClick={deleteContact} data-id={v.id}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                Supprimer
                                            </button>
                                        </div>
                                    </form>
                                )}
                                <br></br>
                                <hr></hr>
                                <div className="col-10 offset-1">
                                    <form onSubmit={addContact}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Nom"
                                                onChange={onNewLastnameChange} value={newLastname} required/>
                                            <input type="text" className="form-control" placeholder="Prénom"
                                                onChange={onNewFirstnameChange} required
                                                value={newFirstname} />
                                            <input type="text" className="form-control" placeholder="E-mail"
                                                onChange={onNewEmailChange} required
                                                value={newEmail} />
                                            <button type="submit" className="btn btn-success" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                Ajouter
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        currentUser.roles.includes("ROLE_ADMIN") &&
                        <div className={(tab !== 1 ? "visually-hidden" : "")}>
                            <form className="col-10 offset-1" onSubmit={addNewGroup}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Groupe de contact</span>
                                    <input type="text" className="form-control" placeholder="Code" value={newCode} onChange={onNewCodeChange} />
                                    <input type="text" className="form-control" placeholder="Description" value={newDescription} onChange={onNewDescriptionChange} />
                                    <textarea className="form-control" placeholder="Autorisations" value={newAuth} onChange={onNewAuthChange} />
                                    <button type="submit" className="btn btn-secondary" disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Enregistrer</span>
                                    </button>
                                </div>
                            </form>
                            <br></br>

                            {groups.map((v, k) =>
                                <div key={"gp-" + v.id} className="col-10 offset-1">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1">{v.id}</span>
                                        <input type="text" className="form-control" placeholder="Code"
                                            defaultValue={v.code} readOnly />
                                        <input type="text" className="form-control" onChange={(e) => onGroupChange(e, k, "description")}
                                            placeholder="Description" defaultValue={v.description} />
                                        <textarea className="form-control" placeholder="Autorisations"
                                            defaultValue={v.authorization} onChange={(e) => onGroupChange(e, k, "authorization")} />

                                        <button className="btn btn-secondary" onClick={() => updateGroup(k)}>
                                            {loading && <ButtonWaiting />}Mettre à jour
                                        </button>

                                        <button type="submit" className="btn btn-danger" disabled={loading}
                                            data-id={v.id} onClick={deleteGroup}>
                                            {loading && <ButtonWaiting />}Supprimer
                                        </button>
                                    </div>
                                </div>
                            )}


                        </div>

                    }

                </div>
            }
        </div>
    )

}

export default ContactsMngt;