import { useEffect, useState } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ticketsDao from "../../services/qhse/ticketsDao";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import errorManagement from "../../services/errorManagement";
import { scrollToTop } from "../../functions/PageAnimation";
import { getFrFormat } from "../../functions/StrFunctions";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../common/smallComponents.js/ReactTable";

const PPM = (props) => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        ticketsDao.getPPM().then(
            (response) => {
                setList(response)
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const onUpdate = (e, k, field, id) => {
        var arr = [...list];
        var val = e.target.value;

        if (arr[k][field] !== val) {
            var o = {
                field: field,
                value: val,
                id: id
            }
            arr[k][field] = val;

            if( field.endsWith("_cost") ){
                var labor_cost = arr[k].labor_cost || 0;
                var material_cost = arr[k].material_cost || 0;
                var ship_cost = arr[k].ship_cost || 0;
                arr[k].total_cost = parseFloat( labor_cost ) + parseFloat( material_cost ) + parseFloat( ship_cost )
            }

            setList(arr);
            ticketsDao.updatePPM(o);
        }
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('date',
            { header: 'Date', cell: (i) => <div className="text-center">{getFrFormat(i.getValue(), true)}</div> }));


        arr.push(columnHelper.accessor('business',
            { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));


        arr.push(columnHelper.accessor('machines',
            {
                header: 'Machine', cell: (i) => <div className="text-center">
                    {i.getValue()} {i.row.original.first_name}</div>
            }));

        arr.push(columnHelper.accessor('chef_proj',
            { header: 'Chef de projet', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('resp_med_mep',
            { header: 'Resp. MeS', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('description',
            {
                header: 'Description', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('cause',
            { header: 'Cause', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('labor_cost',
            {
                header: 'Coût humain', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "labor_cost", i.row.original.row_id)} />
                </div>
            }));

        arr.push(columnHelper.accessor('material_cost',
            {
                header: 'Coût matériel', cell: (i) => <div className="text-center">
                    <input type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "material_cost", i.row.original.row_id)} />
                </div>
            }));

            arr.push(columnHelper.accessor('ship_cost',
                {
                    header: 'Coût transport', cell: (i) => <div className="text-center">
                        <input type="number" className="form-control" defaultValue={i.getValue()}
                            onBlur={(e) => onUpdate(e, i.row.id, "ship_cost", i.row.original.row_id)} />
                    </div>
                }));


            arr.push(columnHelper.accessor('total_cost',
                {
                    header: 'Coût total', cell: (i) => <div className="text-center">
                        {i.getValue()}</div>
                }));

        arr.push(columnHelper.accessor('status',
            {
                header: 'Statut', cell: (i) => <div className="text-center">
                    <select className="form-select" defaultValue={i.getValue()} style={{minWidth: "130px"}}
                        onChange={(e) => onUpdate(e, i.row.id, "status", i.row.original.row_id)}>
                        <option value="0">Non traité</option>
                        <option value="1">En cours</option>
                        <option value="2">Traité</option>
                    </select>
                </div>
            }));

        arr.push(columnHelper.accessor('coms',
            {
                header: 'Commentaires', cell: (i) => <div className="text-center">
                    <textarea type="number" className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onUpdate(e, i.row.id, "coms", i.row.original.row_id)} />
                </div>
            }));



        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <div>
        <h5 className="text-center">Log. PPM</h5>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        <ReactTable columns={columns} data={list}
            origin="ppm_list" classes={"no-padding fixFstCol "} top0={true} />

    </div>

}

export default PPM;