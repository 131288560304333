import { Fragment, useState } from "react";
import parse from "paste-from-excel";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { getHeight } from "../../functions/PageAnimation";

{/**
    @param inputvalue setinputvalue
    @param setinputvalue
    @param label
    @param intiTable function for reset

    e.g:
    const [label, setlabel] = useState({ labels: ["Articles", "Qté"] });
    const [inputvalue, setinputvalue] = useState({ inputs: [] });
*/}
const TableExcelPaste = (p) => {

    const handlePaste = (index, elm, e, i) => {
        return parse(e);
    };

    const handlePaste1 = (index, elm, e, i) => {

        p.setinputvalue((inputvalue) => ({
            ...p.inputvalue,
            inputs: inputvalue.inputs.map((item, i) =>
                index === i
                    ? {
                        ...item,
                        [elm]: e.target.value
                    }
                    : item
            )
        }));
    };

    /**
     * Get type of field for input
     * @param {*} elm 
     * @returns 
     */
    const typeOfField = (elm) => {
        elm = elm.toUpperCase();

        if (elm.indexOf("DATE") !== -1) {
            return "date";
        } else if (elm.indexOf("QTÉ") !== -1) {
            return "number";
        } else {
            return "text";
        }
    }

    /**
     * Extends values until last row
     */
    const extendValues = (row, elm, e, column) => {
        var value = e.target.value;
        if (value.trim() === "") return;

        var maxRow = 0;
        p.inputvalue.inputs.map((res, index) => {
            for (const key in res) {
                if (res[key].trim() !== "") {
                    maxRow++;
                    break;
                }
            }
        })

        var arr = [...p.inputvalue.inputs];
        for (let i = row; i < maxRow; i++)
            arr[i][elm] = value;


        p.setinputvalue({ inputs: arr })
    }

    /**
     * Remove one row
     * @param {*} e 
     */
    const removeRow = (e) => {
        var key = e.target.getAttribute("data-key");
        var arr = [...p.inputvalue.inputs];
        arr = arrayRemoveElt(arr, key);
        p.setinputvalue({ inputs: arr })
    }
    

    return <div className="overflow-y-auto" style={{maxHeight: "500px"}}>
        <table className="table table-striped" >
            <thead>
                <tr className="text-center table-info fixed-header-zero">
                    <th>#</th>
                    {p.label.labels.map((elm, ind) => {
                        return (
                            <th>
                                {elm}
                            </th>
                        );
                    })}
                    <th style={{width: "150px"}}>
                        <button className="btn btn-outline-danger" onClick={()=>p.intiTable(0)}>Tout effacer</button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {p.inputvalue.inputs.map((res, index) => {
                    return (
                        <tr key={index}>
                            <th scope="row" className="text-center">{index + 1}</th>
                            {p.label.labels.map((elm, i) => {
                                return (
                                    <Fragment>
                                        <td>
                                            <input
                                                onInput={(e) => {
                                                    handlePaste1(index, elm, e, i);
                                                }}
                                                onPaste={(e) => {
                                                    handlePaste(index, elm, e, i);
                                                }}
                                                onDoubleClick={(e) => {
                                                    extendValues(index, elm, e, i);
                                                }}
                                                type={typeOfField(elm)}
                                                className="form-control"
                                                value={res[elm]}
                                            />
                                        </td>
                                    </Fragment>
                                );

                            })}
                            <td className="text-center">
                                <img src={"/common/remove_icon.png"} className="cursor-pointer" onClick={removeRow}
                                    style={{ width: "30px" }} data-key={index}></img>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
}

export default TableExcelPaste;