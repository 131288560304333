import { Fragment, useEffect, useState } from "react";
import { getCurrentDateTime } from "../../../../functions/StrFunctions";
import ProdTrackingDao from "../../../../services/machines/ProdTrackingDao";
import errorManagement from "../../../../services/errorManagement";
import { confirmAlert } from "react-confirm-alert";

const ActivityMngt = (p) => {
    const [isBlocked, setIsBlocked] = useState(false);
    const [statusByUser, setStatusByUser] = useState([]);
    const [canContinue, setCanContinue] = useState();
    const [leaveTaskMode, setLeaveTaskMode] = useState(false);
    const [partiallyEnded, setPartiallyEnded] = useState(false);

    const prodBlocking = ["Erreur BE", "Pièce NC", "Pièces manquantes",
        "Recherche de matériel", "Erreur de stock", "Pas d'emplacement pour machine", "Absence"];

    const internalInstBlocking = ["Absence ressource", "Matériel défaillant", "Manque d'outil", 
        "Machine non conforme", "Manque consommable", "Consommable non conforme", "Problème automatisme"]

    const externalInstBlocking = ["Pièces manquantes", "Consommable non conforme",
        "Installation du client pas prête", "Non conformité installation client"]

    useEffect(() => {
        setCanContinue(getIfCanContinue());
    }, [p.status])

    // Set if I have already started this task
    function getIfCanContinue() {
        setCanContinue(p.activity.userStatus !== "En attente")
        return false;
    }

    const startTask = () => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Démarré";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        ProdTrackingDao.saveTask(o, "start").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );

    }

    const endTask = (completely) => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Terminé";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;
        o.completely = completely;

        ProdTrackingDao.saveTask(o, "close").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );

    }

    const restartTask = () => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Redémarré";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        ProdTrackingDao.saveTask(o, "restart").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );
    }

    const setBlocking = (blocking_cause) => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Bloqué";
        o.blocking_cause = blocking_cause;
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        ProdTrackingDao.saveTask(o, "blocking").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );
    }

    const unlock = () => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Redémarré";
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        ProdTrackingDao.saveTask(o, "unlock").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );
    }

    const switchPerson = (trigram, user_id) => {
        p.setError();

        confirmAlert({
            title: "Fin de tâche",
            message: "Vous prenez la place de " + trigram + " sur cette tâche ?",
            buttons: [{
                label: "Oui", onClick: () => {
                    p.setLoading(true);
                    var o = { ...p.activity };
                    o.msg = p.user.trigram + " remplace " + trigram;
                    o.date = getCurrentDateTime("en");
                    o.replacedUserId = parseInt(user_id);
                    o.myId = parseInt(p.user.id);

                    ProdTrackingDao.saveTask(o, "switch").then(
                        (response) => {
                            p.setLoading(false);
                            p.setStatus(response);
                            p.setActivity(o);
                            p.setStep("choose_activity");
                        },
                        (error) => {
                            p.setError(errorManagement.handleError(p.props, error));
                            p.setLoading(false);
                        }
                    );

                }
            }, { label: "Non" }]
        });
    }

    const setPause = (blocking_cause) => {
        p.setError();
        p.setLoading(true);

        var o = { ...p.activity };
        o.status = "Pause";
        o.blocking_cause = blocking_cause;
        o.date = getCurrentDateTime("en");
        o.user_id = p.user.id;

        ProdTrackingDao.saveTask(o, "pause").then(
            (response) => {
                p.setLoading(false);
                p.setStatus(response);
                p.setActivity(o);
                p.setStep("choose_activity");
            },
            (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                p.setLoading(false);
            }
        );
    }

    const canReplace = () => {
        if (!p.activity.summary || p.activity.summary.length === 0) return false;
        if (p.activity.machineStatus === "Terminé") return false;

        //f I have done this task and terminated it, I can't replace but restart


        var myTrigram = p.user.trigram;

        //Can replace if itself has not in process task in this subset
        for (let i = 0; i < p.activity.summary.length; i++) {
            if (myTrigram === p.activity.summary[i].trigram) {
                if (p.activity.summary[i].status !== "Terminé" && p.activity.summary[i].status !== "En attente")
                    return false;
            }
        }


        return true;
    }

    const hasDoneThisTask = () => {
        if (!p.activity.summary || p.activity.summary.length === 0) return false;

        var myTrigram = p.user.trigram;

        //Can replace if itself has not in process task in this subset
        for (let i = 0; i < p.activity.summary.length; i++) {
            if (myTrigram === p.activity.summary[i].trigram) {
                if (p.activity.summary[i].status === "Terminé") return true;
            }
        }


        return false;
    }

    return <div>
        <h5 className="text-center mb-3">{p.activity.station} {p.activity.french_label}</h5>

        {!p.loading && <Fragment>
            {(p.activity.userStatus === "En attente" && !hasDoneThisTask()) &&
                <div className="text-center mt-3" onClick={startTask}>
                    <button className="pushable green">
                        <span className="front green">Démarrer tâche</span>
                    </button>
                </div>
            }

            {(p.activity.userStatus === "Terminé" && hasDoneThisTask()) &&
                <div className="text-center mt-3" onClick={restartTask}>
                    <button className="pushable green">
                        <span className="front green">Redémarrer</span>
                    </button>
                </div>
            }

            {(p.activity.userStatus === "Bloqué" || p.activity.userStatus === "Pause") &&
                <div className="text-center mt-3" onClick={unlock}>
                    <button className="pushable green">
                        <span className="front green">Redémarrer</span>
                    </button>
                </div>
            }

            {canReplace() && <Fragment>

                {p.activity.summary.map((v, k) => {
                    return (v.trigram !== p.user.trigram && v.status !== "Terminé") &&
                        <div className="text-center mt-3" key={"replace" + k}>
                            <button className="pushable orange">
                                <span className="front orange" data-key={k}
                                    onClick={() => switchPerson(v.trigram, v.user_id)}>Remplacer {v.trigram}</span>
                            </button>
                        </div>
                })}

                {!hasDoneThisTask() &&
                    <div className="text-center mt-3">
                        <button className="pushable blue">
                            <span className="front blue" onClick={startTask}>Renfort de personnel</span>
                        </button>
                    </div>
                }

            </Fragment>}

            {p.activity.userStatus.endsWith("émarré") && <Fragment>
                {!leaveTaskMode && <div className="text-center mt-3">
                    <button className="pushable green" onClick={() => setLeaveTaskMode(true)}>
                        <span className="front green">Quitter la tâche</span>
                    </button>
                </div>}

                {/** If you leave task you can't leave completelly if the machine status is blocked */}
                {leaveTaskMode && <Fragment>

                    {(!partiallyEnded && p.activity.machineStatus !== "Bloqué") && <Fragment>
                        <h3 className="text-center">Le sous-ensemble est-il complètement terminée ?</h3>

                        <div className="d-flex mt-3">
                            <div className="text-center col-3 ms-auto" style={{ minWidth: "150px" }}>
                                <button className="pushable green mb-5" onClick={() => endTask(true)}>
                                    <span className="front green">Oui</span>
                                </button>
                            </div>

                            <div className="text-center col-3 me-auto" style={{ minWidth: "150px" }}>
                                <button className="pushable red" onClick={() => setPartiallyEnded(true)}>
                                    <span className="front red">Non</span>
                                </button>
                            </div>
                        </div>
                    </Fragment>}

                    {(partiallyEnded || p.activity.machineStatus === "Bloqué") && <Fragment>

                        <div className="text-center col-3 ms-auto me-auto" style={{ width: "300px" }}>
                            <button className="pushable green mb-3" style={{ width: "290px" }}
                                onClick={() => endTask(false)}>
                                <span className="front green">Ma tâche est terminée</span>
                            </button>
                        </div>

                        <div className="text-center col-3 ms-auto me-auto" style={{ width: "300px" }}>
                            <button className="pushable yellow mb-3" style={{ width: "290px" }}
                                onClick={() => setPause("Assistance sur une autre activité")}>
                                <span className="front yellow">Assistance autre act.</span>
                            </button>
                        </div>

                        {p.user.workstation.indexOf("Installateur") === -1 &&
                            prodBlocking.map((v, k) => {
                                return <div className="text-center col-3 ms-auto me-auto" style={{ width: "300px" }} key={"block" + k}>
                                    <button className="pushable red mb-3" style={{ width: "290px" }}
                                        onClick={() => setBlocking(v)}>
                                        <span className="front red">{v}</span>
                                    </button>
                                </div>
                            })
                        }

                        {p.user.workstation === "Installateur B+" &&
                            internalInstBlocking.map((v, k) => {
                                return <div className="text-center col-3 ms-auto me-auto" style={{ width: "300px" }} key={"block" + k}>
                                    <button className="pushable red mb-3" style={{ width: "290px" }}
                                        onClick={() => setBlocking(v)}>
                                        <span className="front red">{v}</span>
                                    </button>
                                </div>
                            })
                        }

                        {p.user.workstation === "Installateur sur site" &&
                            externalInstBlocking.map((v, k) => {
                                return <div className="text-center col-3 ms-auto me-auto" style={{ width: "300px" }} key={"block" + k}>
                                    <button className="pushable red mb-3" style={{ width: "290px" }}
                                        onClick={() => setBlocking(v)}>
                                        <span className="front red">{v}</span>
                                    </button>
                                </div>
                            })
                        }

                        <div className="text-center mt-5">
                            <button className="btn btn-outline-secondary"
                                onClick={() => setPartiallyEnded(false)}>Retour en arrière</button>
                        </div>
                    </Fragment>}

                </Fragment>}
            </Fragment>}

        </Fragment>}


    </div>

}

export default ActivityMngt;