import { Fragment, useEffect, useState } from "react";
import usersDao from "../../../../services/settings/usersDao";
import { forceString, getFrFormat, hasRole } from "../../../../functions/StrFunctions";
import { getHeight } from "../../../../functions/PageAnimation";

const ChooseActivity = (p) => {
    const [list, setList] = useState([]);
    const [noBom, setNoBom] = useState(false);
    const [displayDetail, setDisplayDetail] = useState(true);
    const me = JSON.parse(localStorage.getItem("user"));
    const isAdmin = me && (me.roles.includes("ROLE_ADMIN"));

    {/** Save the workstation  */ }
    const onWorkstationChoose = (e) => {
        e.preventDefault();
        var o = { ...p.user };
        o.workstation = e.target.workstation.value;
        p.setUser(o);

        usersDao.updateMyProfileById(o.id, "workstation", o.workstation);
    }

    useEffect(() => {
        if (p.user.workstation) {
            getTasks();
        } else {
            setList([]);
        }
    }, [p.user, p.status]);

    const getTasks = () => {
        if (p.bom.length === 0) {
            setNoBom(true);
        } else {
            if (p.user.workstation === "Montage") { setList(getAssembly()) }
            else if (p.user.workstation === "Cartérisation") { setList(getCarterisation()) }
            else if (p.user.workstation === "Intégration") { setList(getMecaIntegration()) }
            else if (p.user.workstation === "Intégration électrique") { setList(getElecIntegration()) }
            else if (p.user.workstation === "Installateur B+") { setList(getInsideInstaller()) }
            else if (p.user.workstation === "Installateur sur site") { setList(getExternalInstaller()) }
        }
    }

    function getAssembly() {
        var arr = [];
        var o, ref = "";

        //Give NM but not FO-, IS-, HB-, TU-
        for (let i = 0; i < p.bom.length; i++) {
            o = p.bom[i];
            if (o.ref && o.ref.length > 17 && o.ref.startsWith("NM-")) {
                ref = o.ref.substring(0, 17);
                if (!ref.endsWith("FO-") && !ref.endsWith("IS-") && !ref.endsWith("HB-") && !ref.endsWith("TU-")) {
                    o.code = ref.split("-")[2];
                    o.station = "Montage";
                    arr.push(o);
                }
            }
        }

       //Add cleat chain
       arr.push({
        "french_label": "CHAINE A TASSEAUX",
        "machine": p.bom && p.bom[0].machine,
        "se": "1GEG",
        "ref": "NM-XXXXXXXX-0GC3-000-A",
        "workstation": "Montage",
        "code": "0GC3",
        "station": "Montage"
        });

        //Add Gluing preparation if exists
        const index = p.bom.findIndex(obj => forceString( obj.french_label ).toUpperCase().indexOf("ENCOLLAGE") !== -1);
        
        if(index !== -1){
            arr.push({
                "french_label": "PREP. ENCOLLAGE",
                "machine": p.bom && p.bom[0].machine,
                "se": "1GEG",
                "ref": "NM-XXXXXXXX-0GET-000-A",
                "workstation": "Montage",
                "code": "0GET",
                "station": "Montage"
                });
        }

        return getStatus(arr);
    }

    function getCarterisation() {
        var arr = [];
      
        //Want to merge all subsets in one
        arr.push({
            "french_label": "Cartérisation",
            "machine": p.bom && p.bom[0].machine,
            "se": "1GEG",
            "ref": "NM-XXXXXXXX-CART-000-A",
            "workstation": "Montage",
            "code": "CART",
            "station": "Cartérisation"
        });

        return getStatus(arr);
    }

    function getMecaIntegration() {
        //Assembly + meca integration
        return getAssembly().concat(getIntegration("Intégration méca."));
    }

    function getIntegration(workstation) {
        var arr = [];
        var o, ref = "";

        //Give SE like %EG and ar like AR% and ref not like PR%
        for (let i = 0; i < p.bom.length; i++) {
            o = p.bom[i];
            if (o.ref && o.ref.length > 17) {
                ref = o.ref.substring(0, 17);
                if (o.se.endsWith("EG") && !ref.startsWith("PR-") && forceString(o.ar).startsWith("AR")) {
                    o.code = ref.split("-")[2];
                    o.station = workstation;
                    arr.push(o);
                }
            }
        }

        return getStatus(arr);
    }

    function getElecIntegration() {
        return getIntegration("Intégration élec.");
    }    

    function getInsideInstaller() {
        var arr = [];
      
        arr.push( getCommissTask("Test des entrées et sorties", "0TES", "B+") )
        arr.push( getCommissTask("Réglages mécaniques", "0RGM", "B+") )
        arr.push( getCommissTask("FAT interne", "0FAT", "B+") )
        arr.push( getCommissTask("FAT client", "1FAT", "B+") )
        arr.push( getCommissTask("Démontage machine", "1DMM", "B+") )

        return getStatus(arr);
    }

    function getExternalInstaller() {
        var arr = [];
        arr.push( getCommissTask("Installation machine", "0INM", "B+") )
        arr.push( getCommissTask("Mise en service", "0MES", "B+") )
        arr.push( getCommissTask("Mise en production", "0MEP", "B+") )
        
        return getStatus(arr);
    }

    function getCommissTask(label, code, typeInstaller){
        return {
            "french_label": label,
            "machine": p.bom && p.bom[0].machine,
            "se": "1GEG",
            "ref": "OP-XXXXXXXX-"+code+"-000-A",
            "workstation": "Installateur " + typeInstaller,
            "code": code,
            "station": "Installateur " + typeInstaller
        }
    }

    function getStatus(arr) {
        var machine, ref, station;

        for( let i=0; i<arr.length; i++ ){
            machine = arr[i].machine;
            ref = arr[i].ref;
            station = arr[i].station;
            
            var o = { ...arr[i], ...getMachineStatus(machine, ref, station) };
            arr[i] = {...o};
        }

        arr.sort( (a, b) => { return a.rank - b.rank });

        return arr;
    }

    function getMachineStatus(machine, ref, station){
        var status = {
            machineStatus: "En attente",
            machineStatusBg: getBg("En attente"),
            userStatus: "En attente",
            userStatusBg: getBg("En attente"),
            summary: [],
            rank: 1
        };
      
        for( let j = 0; j < p.status.length; j++ ){
            if( machine.trim() === p.status[j].machine.trim() && ref.trim() === p.status[j].task.trim() &&
                station.trim() === p.status[j].workstation.trim() ){
                    status.machineStatus = p.status[j].machineStatus;
                    status.machineStatusBg = getBg( p.status[j].machineStatus );
                    status.userStatus = getUserStatus( p.status[j].summary) ;
                    status.userStatusBg = getBg( status.userStatus );
                    status.summary = getSummary( p.status[j].summary );
                    status.rank = getRank( status.userStatus );
                    status.start = p.status[j] ? getFrFormat(p.status[j], true) : ""
            }

        }

        return status;
    }

    function getRank(status){
        if( status === "Bloqué" ) return 0;
        if( status === "Démarré" || status === "Redémarré" ) return 1;
        if( status === "Pause" ) return 2;
        if( status === "En attente" ) return 3;
        if( status === "Terminé" ) return 4;
    }

    function getSummary(summary){
        var arr = [];

        if(summary){
            var split = summary.split(",");
            for( let i=0; i<split.length; i++ ){
                var split2 = split[i].split("-");
                arr.push({ trigram: split2[2], status: split2[1], user_id: split2[0]});
            }
        }

        return arr;
    }

    function getUserStatus(summary){
        var status = "En attente";

        if( summary ){
            var id = p.user.id;
            var split = summary.split(",");
            for( let i=0; i<split.length; i++ ){
                var id = parseInt(split[i].substring(0, split[i].indexOf("-")));
                if( id === parseInt(p.user.id) ) return split[i].split("-")[1];
            } 
        }

        return status;
    }

    function getMyStatus(o) {
        var id = p.user.id;
        var workstation = [p.user.workstation.trim()];
        if (p.user.workstation.trim() === "Intégration") workstation.push("Montage");
        var ref = o.ref.trim();

        for (let i = 0; i < p.status.length; i++) {
            if (id === p.status[i].user_id && workstation.includes(p.status[i].workstation.trim())
                && ref === p.status[i].subassembly.trim()) {
                    return {status: p.status[i].status.trim(), comments: p.status[i].blocking_cause};
                }
        }

        return {status: "En attente"};
    }

    function getBg(status) {
        if (!status) return {};

        if (status === "En attente") {
            return { backgroundColor: "rgb(87, 155, 252)", color: "white" }
        } else if (status === "Démarré" || status === "Redémarré") {
            return { backgroundColor: "rgb(253,171,61)" }
        } else if (status === "Terminé" || status === "Chgt. pers.") {
            return { backgroundColor: "rgb(156, 211, 38)" }
        } else if (status === "Bloqué") {
            return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
        } else if (status === "Pause") {
            return { backgroundColor: "rgb(255, 240, 36)" }
        }
    }

    const onChooseSubAssembly = (k) => {
        var o = { ...list[k] };
        o.status = getMyStatus(list[k]);
        p.setActivity(o);
        p.setStep("activity_mngt");
    }

    return <Fragment>

        {!p.user.workstation && <div className="ms-auto me-auto text-center mt-3" style={{ width: "300px" }}>
            <h5>Quel est votre poste ?</h5>

            <form onSubmit={onWorkstationChoose}>
                <div className="input-group mb-3">
                    <select className="form-select" name="workstation">
                        <option selected>Choisissez...</option>
                        { (hasRole(["ROLE_ADMIN"]) || forceString( p.user.workstation ).toLowerCase().indexOf( "installateur" ) === -1) && 
                            <optgroup label="Production">
                            <option value="Cartérisation">Cartérisation</option>
                            <option value="Montage">Montage</option>
                            <option value="Intégration">Intégration mécanique</option>
                            <option value="Intégration électrique">Intégration électrique</option>
                            </optgroup>
                        }
                        <optgroup label="Mise en service">
                            <option value="Installateur B+">Installateur B+</option>
                            <option value="Installateur sur site">Installateur sur site</option>
                        </optgroup>
                        
                    </select>
                </div>

                <button className="btn btn-success">Valider</button>
            </form>

        </div>}

        {noBom && <div className="alert alert-warning mb-3 text-center">La nomenclature de la machine n'a pas été trouvée</div>}

        {list.length > 0 && <Fragment>
            <h5 className="text-center">Choisissez l'activité sur laquelle vous allez travailler</h5>

            <div className="table-responsive" id="status_table">
                <table className="table text-center align-middle m-auto" style={{maxWidth: "800px"}}>
                    <thead>
                        <tr className="fw-bold table-info text-center">
                            <td style={{maxWidth: "200px"}}>
                                Statut activité
                                {isAdmin && 
                                    <div className="fw-normal text-decoration-underline cursor-pointer" onClick={()=>setDisplayDetail(!displayDetail)}>
                                        { displayDetail ? "Pastilles" : "Détail"}</div>
                                }
                            </td>
                            <td>Démarré le</td>
                            <td>Code</td>
                            <td>Libellé</td>
                            <td style={{width: "200px"}}>Mon statut</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((v, k) => {
                            return <tr key={"se" + k}>
                                {displayDetail && <td className="text-center accordion" style={v.machineStatusBg}>
                                    {v.machineStatus}
                                    <div className="fst-italic" style={{fontSize: "12px"}}>
                                    {v.summary.map( (v2, k2) => {
                                        return <div key={k+"-"+k2}><strong>{v2.trigram}</strong>: {v2.status}</div>
                                    })}
                                    </div>
                                </td>}
                                
                                {!displayDetail &&<td>
                                    <div style={Object.assign({width: "40px", height: "40px"}, v.machineStatusBg)} 
                                        className={"rounded-circle m-auto"}>

                                    </div>
                                </td>}
                                <td>{v.start}</td>
                                <td>{v.code}</td>
                                <td>
                                    {v.french_label.trim()}
                                    <div className="fst-italic">{v.station}</div>
                                </td>
                                <td style={v.userStatusBg}>
                                    {v.userStatus}
                                    {v.myStatus === "Chgt. pers." && 
                                        <div className="fst-italic" style={{fontSize: "12px"}}>{v.blocking_cause}</div>
                                    }
                                    <div></div>
                                </td>

                                <td className="text-center">
                                    <button className="btn btn-secondary"
                                        onClick={() => onChooseSubAssembly(k)}>Choisir cette activité</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Fragment>}

    </Fragment>
}

export default ChooseActivity;