import React, { useState } from 'react';
import CheckConnDao from '../../services/common/CheckConnDao';
import { ButtonWaiting } from '../../functions/PageAnimation';
import errorManagement from '../../services/errorManagement';
import ErrorMessage from '../../common/smallComponents.js/ErrorMessage';
import SuccessMessage from '../../common/smallComponents.js/SuccessMessage';


const Test = (props) => {
  const [translatedText, setTranslatedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [msg, setMsg] = useState();

  const translate = async (e) => {
    e.preventDefault();
    var val = e.target.text.value;

    const translation = await CheckConnDao.translate(val, 'ZH');
    setTranslatedText(translation)
  }

  const testSendEmail = () => {
    setLoading(true);

    CheckConnDao.testSendMail().then(() => { setMsg("ok"); setLoading(false); },
      (error) => {
        setError(errorManagement.handleError(props, error));
        setLoading(false);
      });
  }

  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);

  const handleListen = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'fr-FR'; // Set language
    recognition.continuous = true; // Keep listening
    recognition.interimResults = true; // Show partial results
    setIsListening(true);

    recognition.onresult = (event) => {
      const newTranscript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join('');
      setTranscript(newTranscript);
    };

    recognition.onerror = (event) => {
      console.error('Error occurred in recognition: ', event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();

    return () => recognition.stop(); // Stop listening on cleanup
  };

  return <div>
    {error && <ErrorMessage error={error} />}
    {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

    <h6 className='text-center'>Test envoi mail</h6>
    <div className='text-center'>
      <button className='btn btn-success' disabled={loading} onClick={testSendEmail}>
        {loading && <ButtonWaiting />}
        Envoyer</button>
    </div>

    <h1 className='text-center'>Speech Recognition</h1>
      <button onClick={handleListen} disabled={isListening}>
        {isListening ? 'Listening...' : 'Start Listening'}
      </button>
      <p>Transcript: {transcript}</p>

  </div>
};

export default Test;