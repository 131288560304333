import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "customer-client/contracts";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getQuote = (bl) => {
    return axios
        .post(API_URL + "/custom-invoice/get/delivery-note",
            {
                fake_header: authHeader(),
                bl: bl
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const generateCustomInvoice = (info, detail, pdf, discount) => {
    return axios
        .post(API_URL + "/custom-invoice/generate/delivery-note",
            {
                fake_header: authHeader(),
                info: info,
                detail: detail,
                pdf: pdf,
                discount: discount
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPicking = (business) => {
    return axios
        .post(API_URL + "/picking/get",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateNeedDate = (business, date) => {
    return axios
        .post(API_URL + "/picking/update/need-date",
            {
                fake_header: authHeader(),
                business: business,
                date: date
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updatePicking = (field, business, row, value, info) => {
    return axios
        .post(API_URL + "/picking/update/picking",
            {
                fake_header: authHeader(),
                field:field,
                business: business,
                row: row,
                value: value,
                info: info
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const savePicking = (list, send, err, msg) => {
    return axios
        .post(API_URL + "/picking/save",
            {
                fake_header: authHeader(),
                list: list,
                send: send,
                err: err,
                msg: msg
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadPicking = (list) => {
    return axios
        .post(API_URL + "/picking/download/xls",
            {
                fake_header: authHeader(),
                list: list
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const downloadLabels = (list, printBusiness, start, printReliquat, duplicateLabels) => {
    return axios
        .post(API_URL + "/picking/download/labels",
            {
                fake_header: authHeader(),
                list: list,
                printBusiness: printBusiness,
                start: start,
                printReliquat: printReliquat,
                duplicateLabels: duplicateLabels
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifyDelta = (list) => {
    return axios
        .post(API_URL + "/picking/notify/delta",
            {
                fake_header: authHeader(),
                list: list
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPickingList = (displayAll) => {
    return axios
        .post(API_URL + "/picking/list",
            {
                fake_header: authHeader(),
                displayAll: displayAll
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getMissingDBusinessList = () => {
    return axios
        .post(API_URL + "/picking/missing-d",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPickingAlerts = () => {
    return axios
        .post(API_URL + "/picking/get/alerts",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updatePickingList = (o, field) => {
    return axios
        .post(API_URL + "/picking/list/update",
            {
                fake_header: authHeader(),
                o: o,
                field: field
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const closeNeed = (business) => {
    return axios
        .post(API_URL + "/picking/list/close-need",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPackingList = (business) => {
    return axios
        .post(API_URL + "/picking/get/packing-list",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getPackingBusiness = (arr) => {
    return axios
        .post(API_URL + "/picking/get/packing-list/by-business",
            {
                fake_header: authHeader(),
                arr: arr
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const savePackingList = (business, packs, id, othBusiness, isMes) => {
    return axios
        .post(API_URL + "/picking/save/packing-list",
            {
                fake_header: authHeader(),
                business: business,
                packs: packs,
                id: id,
                othBusiness: othBusiness,
                isMes: isMes
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTicket = (machine, type, id) => {
    return axios
        .post(API_URL + "/incident-ticket/get",
            {
                fake_header: authHeader(),
                machine: machine,
                type: type,
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveTicket = (ticket) => {
    return axios
        .post(API_URL + "/incident-ticket/save",
            {
                fake_header: authHeader(),
                ticket: ticket
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTicketsList = () => {
    return axios
        .post(API_URL + "/incident-ticket/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteTicket = (id) => {
    return axios
        .post(API_URL + "/incident-ticket/delete",
            {
                fake_header: authHeader(),
                id: id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const sendTicket = (o) => {
    return axios
        .post(API_URL + "/incident-ticket/send",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getDefaultsList = () => {
    return axios
        .post(API_URL + "/incident-ticket/defaults",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateDefaults = (o) => {
    return axios
        .post(API_URL + "/incident-ticket/defaults/update",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const generateXlsBOM = (business, file) => {
    let formData = new FormData();
    formData.append("business", business);
    formData.append("file", file);
    formData.append("fake_header", token);
    
    return axios({
        method: "post",
        url: API_URL + "/picking/focus/generate-bom",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((response) => {
            return response.data;
        });
}

const generateSparesStats = (month, year) => {
    return axios
        .post(API_URL + "/picking/stats/export",
            {
                fake_header: authHeader(),
                month: month,
                year: year
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const uploadAssembly = (business, files) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) formData.append("files", files[i]);
    formData.append("business", business);
    formData.append("fake_header", token);

    axios({
        method: "post",
        url: API_URL + "/picking/assembly/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
}

const getAssemblyFiles = (business) => {
    return axios
        .post(API_URL + "/picking/assembly/list",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteAssemblyFiles = (business, name) => {
    return axios
        .post(API_URL + "/picking/assembly/delete",
            {
                fake_header: authHeader(),
                business: business,
                name: name
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const notifAssembly = (business, artToAssembly) => {
    return axios
        .post(API_URL + "/picking/assembly/notify",
            {
                fake_header: authHeader(),
                business: business,
                artToAssembly: artToAssembly
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getCommissioningPicking = (business) => {
    return axios
        .post(API_URL + "/picking/commissioning/get",
            {
                fake_header: authHeader(),
                business: business
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveCommissioningPicking = (business, arr, date, notifyPurchase, address) => {
    return axios
        .post(API_URL + "/picking/commissioning/add",
            {
                fake_header: authHeader(),
                business: business,
                arr: arr, 
                date: date,
                notifyPurchase: notifyPurchase,
                address: address
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const checkCommissioningPicking = (business, arr) => {
    return axios
        .post(API_URL + "/picking/commissioning/check",
            {
                fake_header: authHeader(),
                business: business,
                arr: arr
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getQuote,
    generateCustomInvoice,
    getPicking,
    updateNeedDate,
    updatePicking,
    savePicking,
    downloadPicking,
    downloadLabels,
    notifyDelta,
    getPickingList,
    getMissingDBusinessList,
    updatePickingList,
    closeNeed,
    getPackingList,
    savePackingList,
    getTicket,
    getPickingAlerts,
    saveTicket,
    getTicketsList,
    getDefaultsList,
    updateDefaults,
    deleteTicket,
    sendTicket,
    getPackingBusiness,
    generateXlsBOM,
    generateSparesStats,
    uploadAssembly,
    getAssemblyFiles,
    deleteAssemblyFiles,
    notifAssembly,
    getCommissioningPicking,
    saveCommissioningPicking,
    checkCommissioningPicking
}