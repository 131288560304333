import { confirmAlert } from "react-confirm-alert";
import BusinessDao from "../../../../services/machines/BusinessDao";
import { useState } from "react";
import errorManagement from "../../../../services/errorManagement";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../../../common/smallComponents.js/SuccessMessage";
import { scrollToTop } from "../../../../functions/PageAnimation";

const PartSix = (props) => {
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);

    function getEndOfWarranty(){
        var start = props.businessInfo.date_deb_garantie;
        var month = props.businessInfo.garantie_aff;

      
            if( start !== null && month !== null ){
                start = new Date(start);
                month = parseInt( month.split(" ")[0] );
                start = start.setMonth(start.getMonth()+month);
           
                if( !isNaN( start ))
                    return new Date( start ).toISOString().split('T')[0]
                
            }
    }

    const closeBusiness = () => {
        var business = props.businessInfo.id_aff;

        confirmAlert({
            title: "Clôturer l'affaire " + business,
            message: "Cette action va écrire la date de clôture (date du jour) dans la fiche affaire " +
                        "et dans mettre le statut \"Clôturé\" Monday",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        scrollToTop();
                        var arr = new Array();
                        props.machines.map( v => { if(v.id_monday !== null) arr.push(v.id_monday) } )
                        
                        BusinessDao.closeBusiness(business, arr).then(
                            (response) => {
                                setLoading(false);
                                setMsg("Affaire clôturée !");
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const deleteBusiness = () => {
        var business = props.businessInfo.id_aff;

        confirmAlert({
            title: "Supprimer l'affaire " + business,
            message: "Cette action va supprimer la fiche affaire et supprimer les lignes dans Monday",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        scrollToTop();
                        var ids_monday = "";
                        props.machines.map( v => { if(v.id_monday !== null) ids_monday += v.id_monday + "," } )
                        
                        ids_monday = ids_monday.substring(0, ids_monday.lastIndexOf(","));

                        BusinessDao.deleteBusiness(business, ids_monday).then(
                            (response) => {
                                setLoading(false);
                                window.location.href = "/my-space";
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    return (
        <div className="table-responsive">
            { err && <ErrorMessage error={err}/> }
            { loading && <WaitingRoundSnippers /> }
            { msg && <SuccessMessage msg={msg} msgState={setMsg} /> }

            <br></br>
            <div>
                <div style={{ display: "flex" }}>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_commande}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_commande" />
                        <label>Date de la commande (Focus)</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_liv_tecma}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_liv_tecma" />
                        <label>Date de livraison du sous-contractant</label>
                    </div>



                </div>

                <div style={{ display: "flex" }}>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_fat}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_fat" />
                        <label>Date FAT</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_enlev}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_enlev" />
                        <label>Date d'enlèvement</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_livraison}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_livraison" />
                        <label>Date de livraison sur site</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.deb_mes_site}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="deb_mes_site" />
                        <label>Date de début MeS sur site</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.deb_mep}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="deb_mep" />
                        <label>Date de début MeP</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_recept_pv}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_recept_pv" />
                        <label>Date de réception du PV</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.delivery_scheduled}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="delivery_scheduled" />
                        <label>Date de livraison réelle (prévue)</label>
                    </div>

                    <div style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <textarea className="form-control" readOnly>{props.lastDelivery}</textarea>
                    </div>
                    {/*<div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.delivery_done}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="delivery_done" />
                        <label>Date de livraison réelle (réalisée)</label>
                    </div>*/}

                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.delivery_contract}
                            placeholder="xxx" readOnly />
                        <label>Date de livraison contractuelle</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_deb_garantie}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_deb_garantie" />
                        <label>Date de début de la garantie</label>
                    </div>


                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" value={ getEndOfWarranty() }
                            placeholder="xxx" readOnly />
                        <label>Date de fin de la garantie</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_prem_diff}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_prem_diff" />
                        <label>Date de première diffusion</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "50%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_clot_intr}
                            onBlur={props.updateBusinessField} 
                            placeholder="xxx" data-field="date_clot_intr" />
                        <label>Date de clôture intranet</label>
                    </div>

                </div>

            </div>

            {
                !props.readOnly &&
                <div>
                    <div className="divider div-transparent div-arrow-down"></div>
                    <div className="text-center">
                        <button className="btn btn-warning" disabled={loading} onClick={closeBusiness}>Clôturer l'affaire</button>
                        <button className="btn btn-danger" disabled={loading} onClick={deleteBusiness} style={{marginLeft: "50px"}}>Supprimer l'affaire</button>
                    </div>
                </div>
            }

        </div>
    )
}

export default PartSix;