import { useState } from "react";
import { Fragment } from "react";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import Moment from 'moment/moment';
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import MachineInfo from "../../../../services/machines/MachineInfo";
import { downloadSingleFile } from "../../../../services/functions/FilesProcess";
import errorManagement from "../../../../services/errorManagement";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";

const PartNine = (p) => {
    const [typeRevision, setTypeRevision] = useState(2);
    const [comments, setComments] = useState("");
    const [saveInNetwork, setSaveInNetwork] = useState(true);
    const [sendMail, setSendMail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [techSpecDownloading, settechSpecDownloading] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));

    const html = getHtml();

    Moment.locale("fr");

    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState))

    function getHtml() {
        return getText(comments, typeRevision);
    }

    function getText(comments, typeRevision) {
        var html = 'Bonjour,<br><br>';

        html += "La fiche machine " + p.machineInfo.no_machine + " a été ";

        html += typeRevision === 0 ? "lancée." : typeRevision === 2 ? "mise à jour." : "";
        html += "<br>";

        if (typeRevision === 2) html += "Le motif de révision est: <i>" + comments + "</i>.<br><br>";

        html += "Merci d’avance de prendre en compte les modifications.<br>Vous trouverez ci-joint le fichier.";

        html += "<br>";
        html += "<div style='font-family: Arial; font-size: 10px; color: rgb(0, 154, 169); font-weight: bold'>"
        html += "Cordialement<br>Technical department<br>" + user.first_name + " " + user.last_name
        html += "</div>";

        return html;
    }

    const onTypeRevisionChange = (e) => {
        setTypeRevision(e);

        if (e === 1) setSendMail(false);

        const contentBlock = htmlToDraft(getText(comments, e));
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState))

    }

    const onCommentChange = (e) => {
        var value = e.target.value;
        const contentBlock = htmlToDraft(getText(value, typeRevision));
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState))
        setComments(value);
    }

    const onExport = () => {
        var err = "";
        p.setError();

        if (typeRevision === undefined) err += "Choisissez s'il s'agit d'une première diffusion, un duplicata ou une nouvelle révision.<br>";
        if (typeRevision !== undefined && typeRevision === 2 && comments.trim() === "") err += "Saisissez le suivi de révision ou \"duplicata\".<br>";

        if (err.length > 0) {
            p.setError(err);
            scrollToTop();
            return;
        }

        var o = {
            machine: p.machineInfo.no_machine,
            typeRevision: typeRevision,
            sendMail: sendMail,
            saveInNetwork: saveInNetwork,
            comments: comments,
        }

        //If notify email, convert to html
        if (sendMail) {
            var mail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            o.mailBody = mail;
        }

        var log = { ...logObj };
        log.id = p.machineInfo.no_machine;
        log.origin = "machine";
        log.log_text = "Export de " + ( typeRevision === 0 ? "première diffusion" : typeRevision === 1 ? "duplicata" : "nouvelle révision" ) 
            + " de la fiche machine"
            + (saveInNetwork ? " sauvegardé sur le serveur" : " sauvegardé en local") 
            + " " + ( sendMail ? ", mail envoyé" : "mail non envoyé");
        log.old_value = "";
        log.new_value = comments;

        setLoading(true);
        MachineInfo.exportMachineCard(o).then(
            (response) => {

                if (!saveInNetwork) {
                    var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                    downloadSingleFile("temp", fileName);
                }
                var arr = [...p.revisions];
                arr.push({
                    date_suivi: "A l'instant",
                    commentaire_suivi: comments
                });
                p.setRevisions(arr);
                setLoading(false);
                Miscellaneous.saveLog(log)
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }

    const exportTechSpec = () => {
        p.setError();

        settechSpecDownloading(true);
        MachineInfo.exportTechnicalSpec(p.machineInfo.no_machine).then(
            (response) => {
                var fileName = response.substring(response.lastIndexOf("\\") + 1, response.length);
                downloadSingleFile("temp", fileName);
                settechSpecDownloading(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                settechSpecDownloading(false);
            }
        )
    }

    return <div style={{ padding: "10px" }}>
        <br></br>
        <h5>Téléchargement</h5>
        <ul>
            <li>
                {!techSpecDownloading && <a href="#" onClick={exportTechSpec}>Spécifications techniques</a>}
                {techSpecDownloading && <div><ButtonWaiting />Téléchargement en cours</div>}
            </li>
        </ul>

        <div className="card">
            <div className="card-header mb-3">
                <h4>Générer une fiche machine</h4>
            </div>

            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <label className="form-check-label fw-bold" style={{ width: "100px" }}>Il s'agit de</label>
                </div>

                {
                    (p.revisions && p.revisions.length <= 0) &&
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" checked={typeRevision === 0}
                            onClick={() => onTypeRevisionChange(0)} />
                        <label className="form-check-label" for="inlineRadio1">Une première diffusion</label>
                    </div>
                }
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" checked={typeRevision === 1}
                        onClick={() => onTypeRevisionChange(1)} />
                    <label className="form-check-label" for="inlineRadio1">Un duplicata</label>
                </div>

                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" checked={typeRevision === 2}
                        onClick={() => onTypeRevisionChange(2)} />
                    <label className="form-check-label" for="inlineRadio1">Une nouvelle révision</label>
                </div>
            </div>

            {
                typeRevision === 2 &&
                <div className="form-floating mb-3">
                    <textarea className="form-control" placeholder="Leave a comment here"
                        value={comments} onChange={onCommentChange}></textarea>
                    <label for="floatingTextarea">Saisissez un suivi de révision</label>
                </div>
            }

            <div className="mb-3">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" checked={saveInNetwork} onClick={(e) => setSaveInNetwork(e.target.checked)} />
                    <label className="form-check-label" for="inlineCheckbox1">Enregister directement sur le serveur</label>
                </div>
                {
                    typeRevision !== 1 &&
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" checked={sendMail} onClick={(e) => setSendMail(e.target.checked)} />
                        <label className="form-check-label" for="inlineCheckbox2">Notifier par mail</label>
                    </div>
                }
            </div>

            {
                sendMail &&
                <Editor editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="draftEditor-wrapper" editorClassName="draftEditor-editor" />
            }


            <br></br>

            <div className='alert alert-info col-8 offset-2'>
                <h5>Révision(s) déjà saisie(s)</h5>
                {
                    p.revisions &&
                    <Fragment>
                        <ul>
                            {
                                p.revisions.map((v, k) => {
                                    return <li key={"rev" + k}>{v.date_suivi}: {v.commentaire_suivi}</li>
                                })
                            }
                        </ul>
                    </Fragment>
                }
            </div>

            <br></br>

            <div className='text-center'>
                <button className='btn btn-success' onClick={onExport} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Exporter
                </button>
            </div>
        </div>

        <br></br>

    </div>

}

export default PartNine;