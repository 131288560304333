import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { getDateTimeForInput, getDateTimeFromDb, getEnFormat, getFrFormat } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import ProdTrackingDao from "../../services/machines/ProdTrackingDao";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { getExpander } from "../../common/smallComponents.js/Filter";
import { getValue } from "../../functions/XlsxMgt";

const ProdTrackingListV2 = (props) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const [detail, setDetail] = useState();
    const [searchDetail, setSearchDetail] = useState(false);
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");
    const isN1 = user && user.roles.includes("ROLE_WORKSHOP_N1");

    useEffect(() => {
        ProdTrackingDao.getTrackingListV2().then(
            (response) => {
                var arr = [];
                for (let i = 0; i < response.length; i++) {
                    response[i].subRow = JSON.parse(response[i].subRow);
                    arr.push(response[i]);
                    //if( response[i].machine.indexOf("2180") !== -1 ) console.log( response[i] )
                }
                setData(arr);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            });
    }, []);

    function getSubAss(val) {
        if (!val || val.split("-").length < 3) return "";

        return val.split("-")[2];
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(getExpander(columnHelper));

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className="fw-bold text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('machine', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue().trim()}</div>
        }));

        arr.push(columnHelper.accessor('workstation', {
            header: 'Poste',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('french_label', {
            header: 'Tâche',
            cell: i => <div className="text-center">{getLabel(i.getValue(), i.row.original.task)}</div>
        }));

        arr.push(columnHelper.accessor('task', {
            header: 'S/E',
            cell: i => <div className="text-center">{getSubAss(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('machineStatus', {
            header: 'Statut en cours',
            cell: i => <div className="fw-bold text-center" style={getBg(i.getValue())}>
                {i.getValue()}
            </div>
        }));

        arr.push(columnHelper.accessor('start_corr', {
            header: 'Date de début',
            cell: i => <div className="text-center">
                <input type="datetime-local" className="form-control"
                    readOnly={!isN1 && !isAdmin}
                    onBlur={(e) => onMachineStatusChange(e, i.row.id, "[start]")}
                    defaultValue={getEnFormat(i.getValue()).replace(" ", "T")}></input>
            </div>
        }));

        arr.push(columnHelper.accessor('end_corr', {
            header: 'Date de fin',
            cell: i => <div className="text-center">
                <input type="datetime-local" className="form-control"
                    readOnly={!isN1 && !isAdmin}
                    onBlur={(e) => onMachineStatusChange(e, i.row.id, "[end]")}
                    defaultValue={getEnFormat(i.getValue()).replace(" ", "T")}></input>
            </div>
        }));

        return arr;
    }

    function getLabel(label, ref) {
        var codes = {
            "XGC3": "CHAINE A TASSEAUX",
            "CART": "CARTERISATION",
            "0GET": "PREP. ENCOLLAGE",
            //Installer b+
            "0TES": "Test des entrées et sorties",
            "0RGM": "Réglages mécaniques",
            "0FAT": "FAT interne",
            "1FAT": "FAT client",
            "1DMM": "Démontage machine",

            //Installer ext
            "0INM": "Installation machine",
            "0MES": "Mise en service",
            "0MEP": "Mise en production"
        }

        //If there is a label return it else get the custom label not in BOM
        if (label) {
            return label;
        } else {
            var split = ref.split("-");
            if( split.length > 2 ){
                return codes[ split[2] ] || "?";
            }else{
                return "?";
            }
        }
    }

    function getBg(status) {
        if (!status) {
            return { backgroundColor: "rgb(87, 155, 252)" }
        } else if (status === "Démarré" || status === "Redémarré") {
            return { backgroundColor: "rgb(253,171,61)" }
        } else if (status === "Terminé") {
            return { backgroundColor: "rgb(156, 211, 38)" }
        } else if (status === "Bloqué") {
            return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
        } else if (status === "Chgt. pers.") {
            return { backgroundColor: "rgb(	253, 253, 150)" }
        } else if (status === "Pause") {
            return { backgroundColor: "rgb(255, 240, 36)" }
        }
    }

    /**
     * Change machine status 
     */
    const onMachineStatusChange = (e, k, field) => {
        var arr = [...data];
        var val = e.target.value;

        updateMachineStatus({
            machine: arr[k].machine,
            task: arr[k].task,
            workstation: arr[k].workstation,
            field: field,
            value: val
        })

        if (field === "status") {
            arr[k].machineStatus = val;
        } else {
            console.log( field.replace("[", "").replace("]", "") + "_corr" )
            arr[k][field.replace("[", "").replace("]", "") + "_corr"] = val;
        }

        setData(arr);
    }

    /**
     * Change data in status  table
     */
    function updateMachineStatus(o) {
        ProdTrackingDao.updateStatus(o).then(() => { },
            (error) => { setError(errorManagement.handleError(props, error)); setDetail() });
    }

    //Set columns
    const columns = getColumns();

    const onChangeDetail = (k, e, field, id) => {
        var arr = [...data];
        var detail = { ...arr[id] };

        detail.subRow[k][field] = e.target.value;

        setData(arr);
    }

    /**
     * Change any detail info
     */
    const onBlurDetail = (k, e, field, id) => {
        var arr = [...data];
        var detail = { ...arr[id] };

        detail.subRow[k][field] = e.target.value;

        var o = {
            id: detail.subRow[k].id,
            field: field,
            value: e.target.value
        }

        ProdTrackingDao.updateField(o).then(() => { },
            (error) => { setError(errorManagement.handleError(props, error)); setDetail() });

        //If change the start or end of machine status, change machine status date
        if (field === "date") {
            if (detail.subRow.length - 1 === k) {
                arr[id].start = detail.subRow[k].date

                updateMachineStatus({
                    machine: arr[id].machine,
                    task: arr[id].task,
                    workstation: arr[id].workstation,
                    field: "[start]",
                    value: arr[id].start
                })

                //Sort by date
                detail.subRow = detail.subRow.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Update table
                setData(arr);
            } else if (k === 0 && arr[id].end !== null && arr[id].machineStatus === "Terminé") {
                confirmAlert({
                    title: "Mise à jour",
                    message: "Mettre à jour la date de fin de cette tâche ?",
                    buttons: [{
                        label: "Oui", onClick: () => {
                            arr[id].end = detail.subRow[k].date;

                            //Sort by date
                            detail.subRow = detail.subRow.sort((a, b) => new Date(b.date) - new Date(a.date));

                            setData(arr);

                            updateMachineStatus({
                                machine: arr[id].machine,
                                task: arr[id].task,
                                workstation: arr[id].workstation,
                                field: "[end]",
                                value: arr[id].end
                            })
                        }
                    },
                    { label: "Non" }]
                });

            } else {
                //Sort by date
                detail.subRow = detail.subRow.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Update table
                setData(arr);
            }
        } else {
            setData(arr);
        }


    }

    /**
     *  Delete row
     */
    const deleteRow = (k, id) => {
        var arr = [...data];
        var detail = [...arr[id].subRow];

        confirmAlert({
            title: "Suppression",
            message: "Confirmez-vous la suppression de cette tâche ? Si oui, pensez à changer le statut de la machine si nécessaire",
            buttons: [{
                label: "Oui", onClick: () => {
                    ProdTrackingDao.deleteRow(arr[id].subRow[k].id);
                    detail = arrayRemoveElt(detail, k);
                    arr[id].subRow = detail;
                    setDetail(arr);
                }
            },
            { label: "Non" }]
        });
    }

    /**
     * Sub component table
     */
    const renderSubComponent = (row) => {
        var id = row.id;
        row = row.original.subRow

        return <table className="table text-center">
            <thead>
                <tr className="table-secondary fw-bold">
                    <td>#</td>
                    <td>Exécutant</td>
                    <td>Status</td>
                    <td>Date</td>
                    <td>Cause de blocage</td>
                    <td>Commentaire</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {row && row.map((v, k) => {
                    return <tr key={"subRow" + k}>
                        <td className="fw-bold">{v.id}</td>
                        <td>{v.person}</td>
                        <td className="fw-bold" style={getBg(v.status)}>
                            {v.status}</td>
                        <td>
                            <input type="datetime-local" className="form-control ms-auto me-auto"
                                readOnly={user.id !== v.manager && !isAdmin} style={{ width: "250px" }}
                                onBlur={(e) => onBlurDetail(k, e, "date", id)}
                                onChange={(e) => onChangeDetail(k, e, "date", id)}
                                value={getDateTimeFromDb(v.date, "en")}></input>
                        </td>
                        <td>{v.blocking_cause}</td>
                        <td>
                            {/*<textarea className="form-control"
                                readOnly={user.id !== v.manager && !isAdmin}
                                onBlur={(e) => onBlurDetail(k, e, "coms", id)}
                                defaultValue={v.coms}></textarea>*/}
                            {v.coms}
                        </td>
                        <td>{(user.id === v.manager || isAdmin) &&
                            <button className="btn btn-outline-danger ms-3" onClick={() => deleteRow(k, id)}>Supprimer</button>
                        }</td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    return <Fragment>
        <h5 className="text-center">Suivi d'activité</h5>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}


        <ReactTable columns={columns} data={data} canExpand={true} renderSubComponent={renderSubComponent}
            origin="prodTrackList" classes={"no-padding"} top0={true} />


    </Fragment>
}

export default ProdTrackingListV2;