import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import CustomToolTip, { CustomToolTipClickable } from "../../../common/smallComponents.js/CustomToolTip";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import getUrlParam, { compareTwoDatesObj, extractValueFromTag, getFrFormat, nbFormat } from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import transport from "../../../services/supplyChain/transport";
import { isSideBarOpened, SideNavBarV3 } from "../../../common/smallComponents.js/DesignedIpt";
import { SmallFramecopy } from "../../../common/smallComponents.js/CustomDivs";

const ListRequest = (p) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [loading, setLoading] = useState(true);
    const [sync, setSync] = useState(false);
    const [error, setError] = useState();
    const [list, setList] = useState([]);
    const [originalList, setOriginalList] = useState([]);
    const [displayAll, setDisplayAll] = useState(false);

    const [deliveryDate, setDeliveryDate] = useState();

    const user = JSON.parse(localStorage.getItem("user"));

    const isSupplyChain = user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPPLY_CHAIN"));

    Moment.locale('fr');

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('req_id', {
            header: 'N° expé', cell: (i) =>
                <Fragment>
                    <div className="text-center fw-bold">
                        <a href={"/supply-chain/tools/expedition?page=shipment-request&shipment_id=" + i.row.original.id
                            + (i.row.original.exp_id ? "&id=" + i.row.original.exp_id : "")}>
                            {i.getValue()}
                        </a>
                    </div>
                </Fragment>
        }));

        arr.push(columnHelper.accessor('status', {
            header: 'Statut',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('last_name', { header: 'Demandeur', cell: (i) => <div className="text-center">{i.getValue()} {i.row.original.first_name}</div> }));

        arr.push(columnHelper.accessor('business', { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('exp_lab', { header: 'Libellé d\'expédition', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('type_pack', { header: 'Type de colis', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('machines', {
            header: 'Machines', cell: (i) =>
                <CustomToolTipClickable url="/machine?machine=" splitCode=","
                    value={mergeMachines(i.getValue())} k={i.row.id} />
        }));
        arr.push(columnHelper.accessor('date_load', {
            header: 'Date prév. charg.', cell: (i) =>
                <div className="text-center">{getFrFormat(i.getValue(), true)}</div>
        }));
        arr.push(columnHelper.accessor('date_del', {
            header: 'Date prév. liv.', cell: (i) =>
                <div className="text-center">{getFrFormat(i.getValue(), true)}</div>
        }));
        arr.push(columnHelper.accessor('cn_sender', { header: 'Lieu de chargement', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        arr.push(columnHelper.accessor('country_recip', { header: 'Lieu de livraison', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('exp_id', {
            header: 'Demande de transport',
            cell: (i) => <div className={"text-center fw-bold rounded p-1 " + statusBg(i)}
                style={{ height: "100%", borderRadius: "0.5em" }}>

                {((isSupplyChain || i.getValue()) && i.row.original.status !== null && i.row.original.status !== "Brouillon"
                    && i.row.original.status.indexOf("annulée") === -1) &&
                    <a href={"/supply-chain/tools/expedition?page=shipment-request&shipment_id=" + i.row.original.id
                        + (i.row.original.exp_id ? "&id=" + i.row.original.exp_id : "")}>
                        {!i.getValue() ? "Créer DT" : "TRANS" + i.getValue()}
                    </a>
                }
            </div>
        }));

        arr.push(columnHelper.accessor('detail', {
            header: 'Colisage',
            cell: (i) => <div className="text-center">{getDetail(i.getValue(), "type")}</div>
        }));

        arr.push(columnHelper.accessor('order_nb', {
            header: 'N° de cmd',
            cell: (i) => <div className={"text-center rounded " + orderBg(i)} style={{ height: "100%" }}
                dangerouslySetInnerHTML={{ __html: getOrderLinks(i.getValue()) }}></div>
        }));

        arr.push(columnHelper.accessor('order_price', {
            header: 'Prix',
            cell: (i) => <div className="text-center">{i.getValue() && nbFormat(i.getValue(), 'fr-FR', 2)}</div>
        }));

        arr.push(columnHelper.accessor('bl', {
            header: 'BL',
            cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('detail2', {
            header: 'Date exp. eff.',
            cell: (i) => <div className="text-center">{getExpeditionDate(i)}</div>
        }));

        arr.push(columnHelper.accessor('detail_delivery', {
            header: 'Date liv. eff.',
            cell: (i) => <div className="text-center">{getExpeditionDate(i, "detail_delivery")}</div>
        }));


        return arr;
    }

    function mergeMachines(v) {
        if (!v) return v;

        var o = JSON.parse(v);
        var machines = "";
        for (let i = 0; i < o.length; i++) machines += o[i].value + ","

        return machines.substring(0, machines.lastIndexOf(","));
    }

    function statusBg(i) {
        var status = i.row.original.status;

        if (status === "DT envoyée") return "monday-green";
        if (status === "En attente de DT") {
            if (compareTwoDatesObj(new Date(i.row.original.date_load), new Date()) >= -5) {
                return "monday-red text-white";
            } else {
                return "monday-blue text-white";
            }
        }
        if (status === "DT non envoyée") return "monday-orange";
    }

    function orderBg(i) {
        var row = i.row.original;
        var status = row.status;

        if ((row.transp_aff && row.transp_aff.indexOf("B+") === -1) || isS2C(row.detail)) {
            //If client in charge of transportation, 
            return "";
        } else if (status === "DT envoyée") {
            if (!row.order_nb) {
                if (compareTwoDatesObj(new Date(row.date_load), new Date()) >= -5) {
                    return "monday-red text-white";
                } else {
                    return "monday-blue text-white";
                }
            } else {
                return "monday-green";
            }
        }

        return "";
    }

    function getExpeditionDate(i, field = "detail") {
        var row = i.row.original;
        var dates = getDetail(row[field], "load").split("<br/>");
        dates = arrayRemoveElt(dates, dates.length - 1);
        var ids = getDetail(row[field], "id").split("<br/>");
        var types = getDetail(row[field], "type").split("<br/>");
        var machines = getDetail(row[field], "machines").split("<br/>");

        var tooltip = "";
        var toDo = [];
        var toDoOth = [];

        for (let i = 0; i < dates.length; i++) {
            if (dates[i] !== "1900-01-01 00:00:00") { tooltip += getFrFormat(dates[i], true) + "<br/>"; }
            else if (types[0].indexOf("camion") !== -1) { toDo.push(i + 1); }
            else if (types[0].indexOf("palette") !== -1 || types[0].indexOf("autre") !== -1) {
                toDoOth.push(i + 1);
                break;
            }
        }

        let elt = [];

        if (field === "detail") {
            toDo.map((v, k) => elt.push(<Fragment>
                <a target="_blank" href={"/supply-chain/notify/expedition?business="
                    + list[i.row.id].business + "&truck_nb=" + v + "&detail_id=" + ids[v - 1]
                    + "&exp_id=" + row.id}>Camion {v}
                    {isS2C(list[i.row.id][field]) ? " (S2C)" : ""}

                </a><br></br></Fragment>))

            toDoOth.map((v, k) => elt.push(<Fragment>
                <a target="_blank" href={"/supply-chain/notify/expedition/pack?"
                    + "&exp_id=" + row.id}> {types[0]}
                </a><br></br>
            </Fragment>))
        } else {
            toDo.map((v, k) => elt.push(<Fragment>
                <a target="_blank" className="text-decoration-underline text-primary cursor-pointer"
                    onClick={() => setDeliveryDate({
                        business: list[i.row.id].business,
                        truck_nb: v,
                        detail_id: ids[v - 1],
                        exp_id: row.id,
                        machines: machines[v-1]
                    })}>Camion {v}
                    {isS2C(list[i.row.id][field]) ? " (S2C)" : ""}

                </a><br></br></Fragment>))

            toDoOth.map((v, k) => elt.push(<Fragment>
                <a target="_blank" href={"/supply-chain/notify/expedition/pack?"
                    + "&exp_id=" + row.id}> {types[0]}
                </a><br></br>
            </Fragment>))
        }



        if (tooltip !== "") elt.push(<CustomToolTip props={{
            tooltipText: tooltip, originText: tooltip.substring(0, 16) + (tooltip.length > 16 ? "..." : "")
        }} />)

        return elt;
    }

    function isS2C(detail) {
        return detail && detail.indexOf("<truck>S2C") !== -1;
    }

    function getDetail(str, field) {
        var res = "";
        var o = { "Camion": 0, "Palette/caisse": 0, "Autres": 0 }

        if (field === "type") {
            str && str.split(";").map(v => o[extractValueFromTag(v, field)]++)
            if (o["Camion"] > 0) return o["Camion"] + " camion(s)"
            if (o["Palette/caisse"] > 0) res += o["Palette/caisse"] + " palette(s)/caisse(s) ";
            if (o["Autres"] > 0) res += o["Autres"] + " autre(s)";
        } else {
            str && str.split(";").map(v => res += extractValueFromTag(v, field) + "<br/>")
        }

        return res;
    }

    function getOrderLinks(str) {
        if (!str) return "";

        var txt = "";
        str.split(",").map(v => txt += "<a target=\"_blank\" href=\"/purchase/order/edition?order=" + v + "\">" + v + "</a>, ")
        return txt;
    }

    //Set columns
    const columns = getColumns();

    useEffect(() => {
        transport.getList(getUrlParam(p.props, "all") !== null).then(
            (r) => {
                setOriginalList(r);
                hideCompleteExp(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            }
        )
    }, []);

    const hideCompleteExp = (r) => {
        var arr = [];
        setDisplayAll(false);

        var detail;
        for (let i = 0; i < r.length; i++) {
            detail = r[i].detail;
            if (!detail) { arr.push(r[i]) }
            else {
                var dates = getDetail(detail, "load").split("<br/>");
                var found = false;
                for (let j = 0; j < dates.length - 1; j++) {
                    if ((dates[j] === "" && dates.length > 1) || dates[j] === "1900-01-01 00:00:00") {
                        found = true;
                        break;
                    }
                }

                if (found) arr.push(r[i]);
            }
        }

        setList(arr);
    }

    const syncOrderNb = () => {
        setSync(true);
        transport.syncOrderNb().then(
            (r) => {
                window.location.reload();
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSync(false);
            }
        )
    }

    const notifyDelivery = (e) => {
        e.preventDefault();

        var o ={
            business: deliveryDate.business,
            delivery_date: e.target.date.value,
            machines: deliveryDate.machines,
            detailId: parseInt( deliveryDate.detail_id),
            expId: parseInt(deliveryDate.exp_id)
        }

        setSync(true)
        transport.notifyDelivery(o).then(
            (r) => {
                setDeliveryDate();
                setSync(false);
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSync(false);
            }
        )
    }

    return <div className="bg-see custom-bootstrap" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        <SideNavBarV3 setPin={setPin} content={<div className="ps-3">

            {getUrlParam(p.props, "all") !== null &&
                <div className="mb-3">
                    <a href="/supply-chain/tools/expedition?page=tracking">Afficher uniquement mes demandes</a>
                </div>
            }

            {getUrlParam(p.props, "all") === null && <div className="mb-3">
                <a href="/supply-chain/tools/expedition?page=tracking&all=true">Afficher toutes les demandes envoyées</a>
            </div>
            }

            <div className="mb-3">
                <a href="/supply-chain/tools/expedition?page=form">Créer nouvelle exp.</a>
            </div>

            {displayAll && <div className="mb-3">
                <a href="#" onClick={() => hideCompleteExp(originalList)}>Masquer les expéditions terminées</a>
            </div>}

            {!displayAll && <div className="mb-3">
                <a href="#" onClick={() => { setDisplayAll(true); setList([...originalList]) }}>Afficher les expéditions terminées</a>
            </div>}

            <div className="text-center mb-3">
                <button className="btn btn-outline-secondary mb-1" onClick={syncOrderNb} disabled={sync}>
                    {sync && <ButtonWaiting />}Sync. les n° commandes</button>
            </div>
        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h4 className="text-center">Suivi des expéditions</h4>

            {loading && <WaitingRoundSnippers />}

            <ReactTable columns={columns} data={list} top0={true} origin="shipment" />


            {deliveryDate && <SmallFramecopy close={() => setDeliveryDate()} component={
                <div>
                    {console.log(deliveryDate)}
                    <h6 className="text-center">Mise à jour de la date de livraison</h6>
                    <h6 className="text-center mb-3">Affaire: {deliveryDate.business} - Camion: {deliveryDate.truck_nb}</h6>

                    <form onSubmit={notifyDelivery}>
                        <div className="input-group mb-3 ms-auto me-auto" style={{width: "300px"}}>
                            <span className="input-group-text">Date effective</span>
                            <input type="date" className="form-control" name="date" required/>
                        </div>  

                        <div className="text-center">
                            <button className="btn btn-success" disabled={sync}>
                                {sync && <ButtonWaiting />}
                                Sauvegarder</button>
                        </div>
                    </form>
                </div>
            } />

            }
        </div>

    </div>
}

export default ListRequest;